@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


.screenshotsContainer {
  position: relative; /* Set the position to relative */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: white;
}

.screenshotItem {
  margin: 10px;
  box-shadow: none;
  border: none; 
  max-width: 500px;
}

.screenshotItem img {
  width: 100%;
  height: auto;
}

.stickyNote1 {
  position: absolute; /* Position absolutely to overlay */
  top: 320px; /* Adjust top position to where you want the note to appear */
  right: 920px; /* Adjust right position to where you want the note to appear */
  background-color: #fffa65;
  padding: 10px 20px;
  margin: 10px;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
  border-radius: 5px;
  transform: rotate(0deg);
  max-width: 300px;
  word-wrap: break-word;
  z-index: 100; /* Ensure it's above all other content */
}

.stickyNote2 {
  position: absolute; /* Position absolutely to overlay */
  top: 680px; /* Adjust top position to where you want the note to appear */
  right: 420px; /* Adjust right position to where you want the note to appear */
  background-color: #fffa65;
  padding: 10px 20px;
  margin: 10px;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
  border-radius: 5px;
  transform: rotate(0deg);
  max-width: 300px;
  word-wrap: break-word;
  z-index: 100; /* Ensure it's above all other content */
}


.stickyNote3 {
  position: absolute; /* Position absolutely to overlay */
  top: 1080px; /* Adjust top position to where you want the note to appear */
  right: 620px; /* Adjust right position to where you want the note to appear */
  background-color: #fffa65;
  padding: 10px 20px;
  margin: 10px;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
  border-radius: 5px;
  transform: rotate(0deg);
  max-width: 300px;
  word-wrap: break-word;
  z-index: 100; /* Ensure it's above all other content */
}


.stickyNote4 {
  position: absolute; /* Position absolutely to overlay */
  top: 1380px; /* Adjust top position to where you want the note to appear */
  right: 420px; /* Adjust right position to where you want the note to appear */
  background-color: #fffa65;
  padding: 10px 20px;
  margin: 10px;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
  border-radius: 5px;
  transform: rotate(0deg);
  max-width: 300px;
  word-wrap: break-word;
  z-index: 100; /* Ensure it's above all other content */
}


.pageHeader {
  padding: 10px 20px;
  background-color: white; /* Light gray background for the text area */
  text-align: left;
  font-size: 20px; /* Adjust size as needed */
  color: #7f7f7f; /* Dark gray text color */
  margin-left: 100px; /* Adjust margin as needed */
  margin-right: 100px;
  font-family: 'Roboto', sans-serif; 
  line-height: 1.2;
}