.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .title {
    text-align: left;
    color: #333;
  }
  
  .title h1 {
    margin: 0;
    font-size: 48px;
    color: #333;
  }
  
  .title p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }
  
  .navWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .navLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    transition: color 0.3s ease;
  }
  
  .navLink span {
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }
  
  .navLink:hover,
  .active {
    color: #e63946;
  }
  .active {
    font-weight: bold;
    border-bottom: 2px dashed #e63946;
  }

  