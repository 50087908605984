/* ProjectCard.module.css */
.cardContainer {
    display: flex;
    margin-bottom: 20px;
}

.cardImage {
    width: 50%;
}

.cardImage:hover {
    transform: scale(1.05);
    transition: transform 0.5s;
}

.cardText {
    padding: 20px;
    width: 50%;
    font-size: 20px;
}

.redBackground {
    background-color: #e63946; /* Red background */
    color: white;
}

.whiteBackground {
    background-color: white; /* White background */
    color: black;
}

.keyword {
    margin-right: 10px;
    padding: 5px;
    background-color: grey;
    color: white;
    border-radius: 5px;
}

.reverse {
    flex-direction: row-reverse;
}
