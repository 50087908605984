.footer {
    background-color: #f3f3f3;
    text-align: center;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    color: #aaa;
    font-size: 14px;
  }
  