.about-me-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
  }
  
  .profile-card {
    text-align: center;
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .social-icons img {
    width: 30px;
    height: 30px;
  }
  
  .about-me-text {
    max-width: 600px;
  }
  
  .projects-button {
    background-color: #e63946;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .projects-button:hover {
    background-color: #c32f3e;
  }
  